import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { ConfirmDialogProps, useConfirm } from 'components/ConfirmDialog';

type Props = ConfirmDialogProps & LoadingButtonProps;

const ConfirmableLoadingButton = (props: Props) => {
  const { onConfirm, title, text, ...rest } = props;
  const confirm = useConfirm({ onConfirm, title, text });

  return <LoadingButton onClick={confirm} {...rest} />;
};

export default ConfirmableLoadingButton;
