import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from 'components/TextField';
import YesNoSelect from 'components/YesNoSelect';
import DatePicker from 'components/DatePicker';
import UnsentInvoicesCard from 'components/UnsentInvoicesCard';
import UnpaidInvoicesCard from 'components/UnpaidInvoicesCard';
import Section from 'components/Section';
import Table, { TableProps } from 'components/Table';
import InvoicesTableRow from 'components/InvoicesTableRow';
import ActivityIdSelect from 'components/ActivityIdSelect';
import SessionIdSelect from 'components/SessionIdSelect';
import SelectableTableHead from 'components/SelectableTableHead';
import InvoiceActions from 'components/InvoiceActions';
import InvoicesActionsMenu from 'components/InvcoicesActionsMenu';

import { useInvoiceIds, useInvoices } from 'features/Invoice';
import { usePagination } from 'components/Pagination';
import { useSelectable } from 'components/MultiSelect';

import {
  searchParamsToBool,
  useBooleanFilter,
  useDateFilter,
  useIdFilter,
  useTextFilter,
} from 'lib';

import { ClubInvoiceIndexParams } from 'schema';

const buildFilterParams = (state: URLSearchParams): ClubInvoiceIndexParams => {
  const params: ClubInvoiceIndexParams = {};

  if (state.has('session')) {
    params.session = Number(state.get('session'));
  }

  if (state.has('activity')) {
    params.activity = Number(state.get('activity'));
  }

  if (state.has('search')) {
    params.search = state.get('search');
  }

  if (state.has('from')) {
    params.from = state.get('from');
  }

  if (state.has('to')) {
    params.to = state.get('to');
  }

  if (state.has('sent')) {
    params.sent = searchParamsToBool(state, 'sent');
  }

  if (state.has('paid')) {
    params.paid = searchParamsToBool(state, 'paid');
  }

  if (state.has('periodic')) {
    params.periodic = searchParamsToBool(state, 'periodic');
  }

  return params;
};

export type InvoicesTableProps = TableProps;

const InvoicesTable = (props: TableProps) => {
  const [searchParams] = useSearchParams();
  const [pageInfo] = usePagination();
  const { selectAll, clearAll } = useSelectable();

  const params = useMemo(
    () => ({
      ...pageInfo,
      ...buildFilterParams(searchParams),
    }),
    [pageInfo, searchParams]
  );

  const invoices = useInvoices(params);
  const ids = useInvoiceIds(params);

  const handleSelectAll = useCallback(() => {
    selectAll(ids);
  }, [ids, selectAll]);

  useEffect(() => {
    clearAll();
  }, [clearAll, params]);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid xs={4}>
          <UnsentInvoicesCard />
        </Grid>

        <Grid xs={4}>
          <UnpaidInvoicesCard />
        </Grid>
      </Grid>

      <Section
        sx={{ mb: 2 }}
        title="Sąskaitos"
        actions={<InvoiceActions params={params} />}>
        <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
          <Grid xs={3} sx={{ pb: 2 }}>
            <TextField label="Paieška" {...useTextFilter('search')} />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <SessionIdSelect label="Grupė" {...useIdFilter('session')} />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <ActivityIdSelect label="Veikla" {...useIdFilter('activity')} />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <YesNoSelect
              fullWidth
              label="Sąskaita išsiųsta"
              {...useBooleanFilter('sent')}
            />
          </Grid>

          <Grid xs={3}>
            <DatePicker
              fullWidth
              label="Sukurta nuo"
              {...useDateFilter('from')}
            />
          </Grid>

          <Grid xs={3}>
            <DatePicker
              fullWidth
              label="Sukurta iki"
              {...useDateFilter('to')}
            />
          </Grid>

          <Grid xs={3} sx={{ pb: 2 }}>
            <YesNoSelect
              fullWidth
              label="Sąskaitos tipas"
              {...useBooleanFilter('periodic')}
              yesText="Periodinis"
              noText="Registracija"
            />
          </Grid>

          <Grid xs={3}>
            <YesNoSelect
              fullWidth
              label="Apmokėta"
              {...useBooleanFilter('paid')}
            />
          </Grid>
        </Grid>
      </Section>

      <Table
        paginated
        bulkSelect
        loading={invoices.isPending}
        count={invoices.data?.meta?.pageInfo.count}
        onSelectAll={handleSelectAll}
        head={
          <SelectableTableHead>
            <TableCell />
            <TableCell component="th">Klientas</TableCell>

            <TableCell component="th" align="center">
              Sąskaitos tipas
            </TableCell>

            <TableCell component="th" align="center">
              Ataskaitinis laikotarpis
            </TableCell>

            <TableCell component="th" align="center">
              Suma
            </TableCell>

            <TableCell component="th" align="center">
              Nuolaida
            </TableCell>

            <TableCell component="th" align="center">
              Sąskaita
            </TableCell>

            <TableCell component="th" align="center">
              Sąskaitos data
            </TableCell>

            <TableCell component="th" align="center">
              Išsiųsta
            </TableCell>

            <TableCell component="th" align="center">
              Sumokėta suma
            </TableCell>

            <TableCell component="th" align="center">
              Apmokėjimo data
            </TableCell>

            <TableCell component="th" align="right"></TableCell>
          </SelectableTableHead>
        }
        actions={<InvoicesActionsMenu />}
        {...props}>
        {(invoices.data?.data ?? []).map(invoice => (
          <InvoicesTableRow
            key={invoice.id}
            id={invoice.id}
            invoice={invoice}
            params={params}
          />
        ))}
      </Table>
    </>
  );
};

export default InvoicesTable;
