import HomePageSettingsForm from 'components/HomePageSettingsForm';
import Section from 'components/Section';

import { useHomePageSettings } from 'features/Menu';

const HomePageSettings = () => {
  const settings = useHomePageSettings();

  return settings.data ? (
    <HomePageSettingsForm settings={settings.data} />
  ) : (
    <Section>Kraunasi...</Section>
  );
};

export default HomePageSettings;
