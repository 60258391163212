import { useCallback, useState } from 'react';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { saveAs } from 'file-saver';

import { ENV, STORAGE_ACCESS_TOKEN } from 'const';
import { useNotification } from 'components/Notifications';
import { ApiErrorResponse, ApiResponse, authRefreshToken } from 'schema';
import { format } from 'date-fns/format';
import { intercept, error } from '@igudo/debugger';

type Subscriber = (ok: boolean) => void;

type FilesMap = {
  [key: string]: File | File[] | null;
};

export type UploadField = {
  currentUrl: string | null;
  uploaded: File | null;
};

function transform(data: any, headers: AxiosHeaders) {
  return headers.getContentType()?.toString() === 'application/json'
    ? JSON.stringify(transformDates(data))
    : data;
}

function transformDates(data: any): any {
  if (data instanceof Date) {
    return format(data, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  }

  if (Array.isArray(data)) {
    return data.map(val => transformDates(val));
  }

  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, transformDates(val)])
    );
  }

  return data;
}

const http = axios.create({
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    transform,
    ...(axios.defaults.transformRequest as Array<any>),
  ],
});

const expiredMessage = 'expired_token';
let isRefreshing = false;
let subscribers: Subscriber[] = [];

function onRefreshed(ok: boolean) {
  subscribers.forEach(cb => cb(ok));
}

function subscribeTokenRefresh(cb: Subscriber) {
  subscribers.push(cb);
}

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);
    if (!token) return config;

    config['headers'] = config.headers ?? {};

    (config.headers as RawAxiosRequestHeaders)['Authorization'] =
      `Bearer ${token}`;

    return config;
  },
  error => Promise.reject(error)
);

if (ENV !== 'production') {
  http.interceptors.response.use(intercept, error);
}

http.interceptors.response.use(
  (response: AxiosResponse<any, any>) => response,

  (error: any) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (!originalRequest) return;

    if (
      error.response.status === 401 &&
      error.response.data?.errorCode === expiredMessage &&
      !originalRequest._retry
    ) {
      const result = new Promise((resolve, reject) => {
        subscribeTokenRefresh((ok: boolean): void => {
          if (!ok) {
            reject(error);
          } else {
            const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);

            if (!token) return reject('No token');

            if (!originalRequest.headers) {
              originalRequest.headers = {};
            }

            originalRequest.headers['Authorization'] = `Bearer ${token}`;

            originalRequest._retry = true;
            resolve(axios(originalRequest));
          }
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        authRefreshToken()
          .then(({ accessToken }) => {
            localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);

            isRefreshing = false;
            onRefreshed(true);
            subscribers = [];
          })
          .catch(() => {
            onRefreshed(false);
          });
      }

      return result;
    }

    return Promise.reject(error);
  }
);

export function useErrorHandler(defaultMessage: string = 'Įvyko klaida') {
  const { pop } = useNotification();

  return (error: AxiosError<ApiErrorResponse<null>>) => {
    const responseMessage = error.response?.data.message;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.error(error);
    }

    pop(
      responseMessage
        ? responseMessage
        : error.message
          ? error.message
          : defaultMessage
    );
  };
}

type UseDownload = [() => void, boolean];

// TODO refactor useDownload to take exported function from the schema same way
// react query does it
export function useDownload(url: string, params: any = null): UseDownload {
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();

  const download = useCallback(() => {
    if (loading) return;

    setLoading(true);

    http
      .get(url, { responseType: 'blob', params })
      .then(response => {
        setLoading(false);

        const fileName =
          response.headers['content-disposition']?.split('filename=')[1];

        saveAs(response.data, fileName);
      })
      .catch(error => {
        setLoading(false);
        handleError(error);
      });
  }, [handleError, loading, url, params]);

  return [download, loading];
}

export async function getData<TResponseData>(url: string) {
  return (await http.get<ApiResponse<TResponseData>>(url)).data.data;
}

export async function deleteData<TResponseData>(url: string) {
  return (await http.delete<ApiResponse<TResponseData>>(url)).data.data;
}

export async function postData<TResponseData, TPost extends any = any>(
  url: string,
  data: TPost
) {
  return (await http.post<ApiResponse<TResponseData>>(url, data)).data.data;
}

export async function postUploadData<TResponseData, TPost extends any = any>(
  url: string,
  data: TPost,
  files: FilesMap
) {
  return (
    await http.post<ApiResponse<TResponseData>>(
      url,
      makeFormData(data, files),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  ).data.data;
}

export async function putData<TResponseData, TPost extends any = any>(
  url: string,
  data: TPost
) {
  return (await http.put<ApiResponse<TResponseData>>(url, data)).data.data;
}

export async function putUploadData<TResponseData, TPost extends any = any>(
  url: string,
  data: TPost,
  files: FilesMap
) {
  return (
    await http.put<ApiResponse<TResponseData>>(url, makeFormData(data, files), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data.data;
}

export function makeFormData(data: unknown, files: FilesMap | null) {
  const formData = new FormData();

  formData.append('data', JSON.stringify(transformDates(data)));

  if (files) {
    Object.keys(files).forEach(name => {
      const file = files[name];

      if (file) {
        if (Array.isArray(file)) {
          file.forEach(item => {
            formData.append(`${name}[]`, item);
          });
        } else {
          formData.append(name, file);
        }
      }
    });
  }

  return formData;
}

export default http;
