import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography/Typography';
import PaymentMarkPaidButton from 'components/PaymentMarkPaidButton';
import TableRow from 'components/TableRow';
import UserWithContacts from 'components/UserWithContacts';

import { formatDateTime } from 'lib';
import { AdminPaymentListView } from 'schema';

type Props = {
  payment: AdminPaymentListView;
};

const PaymentsTableRow = ({ payment }: Props) => {
  const { transactionId, purpose, amount, status, paidAt, order } = payment;

  return (
    <TableRow>
      <TableCell>{transactionId}</TableCell>
      <TableCell>{purpose}</TableCell>
      <TableCell align="center">{order?.activity?.name}</TableCell>

      <TableCell align="center">
        {order?.attendants.map(({ session }, i) => (
          <Typography key={i}>{session?.name}</Typography>
        ))}

        <Typography color="text.secondary" variant="body2">
          {order?.attendants.map(({ fullName }) => fullName).join(', ')}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {order?.user && <UserWithContacts user={order.user} />}
      </TableCell>

      <TableCell align="center">{amount}</TableCell>
      <TableCell align="center">{status}</TableCell>

      <TableCell align="center">
        {order?.receivedAt && formatDateTime(order.receivedAt)}
      </TableCell>

      <TableCell align="center">
        {paidAt && formatDateTime(paidAt, true)}
      </TableCell>

      <TableCell align="right">
        <PaymentMarkPaidButton payment={payment} />
      </TableCell>
    </TableRow>
  );
};

export default PaymentsTableRow;
