import Page from 'components/Page';
import Pagination from 'components/Pagination';
import InvoicesTable from 'components/InvoicesTable';
import MultiSelect from 'components/MultiSelect';

const InvoicesList = () => (
  <Page title="Atsiskaitymai">
    <MultiSelect>
      <Pagination>
        <InvoicesTable />
      </Pagination>
    </MultiSelect>
  </Page>
);

export default InvoicesList;
