import HomePageSettings from 'components/HomePageSettings';
import MenuEditor from 'components/MenuEditor';
import Page from 'components/Page';
import Tabber from 'components/Tabber';
import Tabs from 'components/Tabs';

const MenuEdit = () => (
  <Tabs
    tabs={[
      {
        title: 'Viršutinis',
        component: <MenuEditor menuKey="header" />,
        paper: false,
        hash: 'header',
      },
      {
        title: 'Apatinis',
        component: <MenuEditor menuKey="footer" />,
        paper: false,
        hash: 'footer',
      },
      {
        title: 'Titulinis',
        component: <HomePageSettings />,
        paper: false,
        hash: 'home',
      },
    ]}>
    <Page title="Meniu">
      <Tabber />
    </Page>
  </Tabs>
);

export default MenuEdit;
