import { ReactNode } from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = PaperProps & {
  title?: ReactNode;
  actions?: ReactNode;
};

export type SectionProps = Props;

const Section = ({ sx = [], title, actions, children, ...props }: Props) => (
  <Paper sx={[{ p: 2 }, ...(Array.isArray(sx) ? sx : [sx])]} {...props}>
    {(title || actions) && (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {title && (
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        )}

        {actions && <Box>{actions}</Box>}
      </Box>
    )}

    {children}
  </Paper>
);

export default Section;
