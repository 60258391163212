import { useCallback } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Tooltip from '@mui/material/Tooltip';
import LoadingIconButton from 'components/LoadingIconButton';

import { useGenerateMonthly } from 'features/Invoice';
import { useMe } from 'components/AuthGuard';
import { useDownload } from 'lib';
import { useClub } from 'components/ClubProvider';

import { ClubInvoiceExportExcelParams } from 'schema';

type Props = {
  params: ClubInvoiceExportExcelParams;
};

const InvoiceActions = ({ params }: Props) => {
  const me = useMe();
  const { mutate, isPending } = useGenerateMonthly();

  const [download, isDownloading] = useDownload(
    `/api/v1/clubs/${useClub().id}/invoices/xlsx`,
    params
  );

  const handleGenerateClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <>
      <LoadingIconButton loading={isDownloading} onClick={download}>
        <Tooltip title="Exportuoti į Excel">
          <FileDownloadIcon />
        </Tooltip>
      </LoadingIconButton>

      {me.isAdmin && (
        <LoadingIconButton loading={isPending} onClick={handleGenerateClick}>
          <Tooltip title="Generuoti mėnesines sąskaitas">
            <AutorenewIcon />
          </Tooltip>
        </LoadingIconButton>
      )}
    </>
  );
};

export default InvoiceActions;
