import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ConfirmableIconButton from 'components/ConfirmableIconButton';

import { MenuItemListView } from 'schema';

type Props = ListItemProps & {
  selected?: boolean;
  item: MenuItemListView;
  onSelectItem: (item: MenuItemListView) => void;
  onDelete: (item: MenuItemListView) => void;
};

const MenuEditorItemsItem = (props: Props) => {
  const { selected, item, onDelete, onSelectItem, ...rest } = props;

  const handleSelectClick = useCallback(() => {
    onSelectItem(item);
  }, [item, onSelectItem]);

  const handleDeleteClick = useCallback(() => {
    onDelete(item);
  }, [item, onDelete]);

  return (
    <ListItem
      disablePadding
      onClick={handleSelectClick}
      secondaryAction={
        <ConfirmableIconButton
          edge="end"
          size="small"
          text={`Ar tikrai norite ištrinti ${item.name}`}
          onConfirm={handleDeleteClick}>
          <DeleteIcon color="error" fontSize="small" />
        </ConfirmableIconButton>
      }
      {...rest}>
      <ListItemButton selected={selected}>
        <ListItemText primary={item.name} />
      </ListItemButton>
    </ListItem>
  );
};

export default MenuEditorItemsItem;
