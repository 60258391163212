import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import FormTextField from 'components/FormTextField';
import Section from 'components/Section';

import { HomePageSettings } from 'schema';
import { useUpdateHomePageSettings } from 'features/Menu';
import { object, string } from 'yup';

type Fields = {
  link: string;
};

type Props = {
  settings: HomePageSettings;
};

const schema = object({
  link: string().required('Prašome įvesti titulinio puslapio nuorodą'),
});

const HomePageSettingsForm = ({ settings }: Props) => {
  const { mutate, isPending } = useUpdateHomePageSettings();

  const initial = {
    link: settings.link ?? '',
  };

  const handleSubmit = useCallback(
    (form: Fields) => {
      mutate(form);
    },
    [mutate]
  );

  return (
    <Formik<Fields>
      initialValues={initial}
      validationSchema={schema}
      onSubmit={handleSubmit}>
      <Form>
        <Section sx={{ mb: 2 }}>
          <FormTextField
            fullWidth
            name="link"
            label="Titulinio puslapio nuoroda"
          />
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={isPending}>Saugoti</FormButton>
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default HomePageSettingsForm;
