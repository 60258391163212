import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PageSkeleton from 'components/PageSkeleton';
import Page from 'components/Page';
import InvoiceTable from 'components/InvoiceTable';
import InvoiceCorrectionForm, {
  FormFields,
} from 'components/InvoiceCorrectionForm';
import Section from 'components/Section';
import InvoiceDownload from 'components/InvoiceDownload';
import InvoiceSyncStatus from 'components/InvoiceSyncStatus';

import {
  useInvoice,
  useUpdateInvoice,
  useUpdateSendInvoice,
} from 'features/Invoice';
import { formatDateTime } from 'lib';

const handleSubmit = () => {
  // nothing to do here realy but formik insits on providing onSubmit prop
};

const InvoiceEdit = () => {
  const { id } = useParams();
  const invoiceId = Number(id);
  const invoiceQuery = useInvoice(invoiceId);
  const updateInvoice = useUpdateInvoice(invoiceId);
  const updateAndSend = useUpdateSendInvoice(invoiceId);

  const handleSend = useCallback(
    (form: FormFields) => {
      updateAndSend.mutate(form);
    },
    [updateAndSend]
  );

  const handleSave = useCallback(
    (form: FormFields) => {
      updateInvoice.mutate(form);
    },
    [updateInvoice]
  );

  if (!invoiceQuery.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  const { user, notes, sentAt, invoiceNumber, paymentLink, isLocked } =
    invoiceQuery.data;

  const initial = {
    notes: notes ?? '',
  };

  return (
    <Page title={invoiceQuery.data.invoiceNumber}>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <Section title="Sąskaita">
            <Grid
              container
              spacing={2}
              sx={{ mt: 3, mb: 2, alignItems: 'center' }}>
              <Grid xs={4}>Sąskaitos dokumentas:</Grid>
              <Grid xs={8}>
                {sentAt ? (
                  <InvoiceDownload invoice={invoiceQuery.data} />
                ) : (
                  invoiceNumber
                )}
              </Grid>

              <Grid xs={4}>Mokėjimo nuoroda:</Grid>
              <Grid xs={8}>{paymentLink}</Grid>

              <Grid xs={4}>Sąskaita išsiųsta:</Grid>
              <Grid xs={8}>{sentAt ? formatDateTime(sentAt) : 'Ne'}</Grid>

              <Grid xs={4}>Buhalterijos sinchronizacija:</Grid>
              <Grid xs={8}>
                <InvoiceSyncStatus invoice={invoiceQuery.data} />
              </Grid>
            </Grid>

            <Box sx={{ mb: 4 }}>
              <InvoiceTable invoice={invoiceQuery.data} />
            </Box>

            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              Sąskaitos korekcijos
            </Typography>

            <Formik<FormFields> initialValues={initial} onSubmit={handleSubmit}>
              <InvoiceCorrectionForm
                readOnly={isLocked}
                sending={updateAndSend.isPending}
                saving={updateInvoice.isPending}
                onSave={handleSave}
                onSend={handleSend}
              />
            </Formik>
          </Section>
        </Grid>

        <Grid xs={4}>
          <Section title="Mokėtojas">
            <List sx={{ width: '100%' }}>
              <ListItem disableGutters>
                <ListItemText
                  primary="Vardas pavardė"
                  secondary={user.fullName}
                />
              </ListItem>

              <ListItem disableGutters>
                <ListItemText primary="El. paštas" secondary={user.email} />
              </ListItem>

              <ListItem disableGutters>
                <ListItemText primary="Telefonas" secondary={user.phone} />
              </ListItem>
            </List>
          </Section>
        </Grid>
      </Grid>
    </Page>
  );
};

export default InvoiceEdit;
