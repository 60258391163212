import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useNavigateRow(id: number) {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(id.toString());
  }, [id, navigate]);

  return {
    onClick,
    hover: true,
    sx: { cursor: 'pointer' },
  };
}
