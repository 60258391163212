import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import { useMe } from 'components/AuthGuard';
import { selected } from 'components/MainMenu';

const defaultOpen = (pathname: string) =>
  selected('/settings', pathname) ||
  selected('/attendants', pathname) ||
  selected('/categories', pathname) ||
  selected('/menu', pathname) ||
  selected('/pages', pathname) ||
  selected('/public-holidays', pathname);

const MainMenuSettings = () => {
  const { pathname } = useLocation();
  const [settingsOpen, setSettingsOpen] = useState(defaultOpen(pathname));
  const me = useMe();

  const handleSettingsClick = () => {
    setSettingsOpen(v => !v);
  };

  return (
    <>
      <ListItemButton onClick={handleSettingsClick}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>

        <ListItemText primary="Nustatymai" />
        {settingsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            href="settings"
            component={Link}
            selected={selected('/settings', pathname)}>
            <ListItemText inset primary="Išvaizda" />
          </ListItemButton>

          {me.isAdmin && (
            <>
              <ListItemButton
                href="categories"
                component={Link}
                selected={selected('/categories', pathname)}>
                <ListItemText inset primary="Kategorijos" />
              </ListItemButton>

              <ListItemButton
                href="menu"
                component={Link}
                selected={selected('/menu', pathname)}>
                <ListItemText inset primary="Meniu" />
              </ListItemButton>

              <ListItemButton
                href="pages"
                component={Link}
                selected={selected('/pages', pathname)}>
                <ListItemText inset primary="Puslapiai" />
              </ListItemButton>

              <ListItemButton
                href="public-holidays"
                component={Link}
                selected={selected('/public-holidays', pathname)}>
                <ListItemText inset primary="Valstybinės šventės" />
              </ListItemButton>

              <ListItemButton
                href="payments"
                component={Link}
                selected={selected('/payments', pathname)}>
                <ListItemText inset primary="Neopay mokėjimai" />
              </ListItemButton>
            </>
          )}
        </List>
      </Collapse>
    </>
  );
};

export default MainMenuSettings;
