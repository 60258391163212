import { MouseEvent, SyntheticEvent, useCallback } from 'react';

import Button from '@mui/material/Button';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';

import { useDialog } from 'components/Dialogs';
import { FormikHelpers } from 'formik';

type Props = {
  title?: string;
  text?: string;
  args?: any[];
  onConfirm?: (event?: MouseEvent<HTMLButtonElement>) => void;
};

type ConfirmableFormProps<T> = {
  title?: string;
  text?: string;
  onConfirm?: (values: T, helpers: FormikHelpers<T>) => void;
};

export type ConfirmDialogProps = Props;

export function useConfirmableForm<T>(props: ConfirmableFormProps<T>) {
  const [, { open }] = useDialog('confirm');

  return useCallback(
    (...args: any[]) => {
      open({ ...props, args });
    },
    [open, props]
  );
}

export const useConfirm = (props: Props) => {
  const [, { open }] = useDialog('confirm');

  return useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      open(props);
    },
    [open, props]
  );
};

function ConfirmDialog(props: Props) {
  const [, { close }] = useDialog('confirm');

  const {
    title = 'Patvirtinimas',
    text = 'Ar tikrai norite atlikti šį veiksmą?',
    args,
    onConfirm,
  } = props;

  const handleConfirm = useCallback(() => {
    close();
    onConfirm && (args ? onConfirm(...args) : onConfirm());
  }, [close, onConfirm, args]);

  return (
    <Dialog dialog="confirm">
      <DialogTitle onClose={close}>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>

      <DialogActions>
        <Button autoFocus onClick={close}>
          Ne
        </Button>

        <Button onClick={handleConfirm}>Taip</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
