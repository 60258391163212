import {
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import MenuIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';

type Props = Omit<MenuProps, 'open' | 'anchorEl' | 'onClose'> & {
  renderActivator?: (
    // TODO should use context instead. Add open function to it
    open: (event: MouseEvent<HTMLElement>) => void
  ) => ReactNode;
};

type MenuContextType = {
  close: () => void;
};

const MenuContext = createContext<MenuContextType>({
  close: () => {},
});

export const useMenu = () => useContext(MenuContext);

const MenuButton = ({ renderActivator, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <MenuContext.Provider value={{ close }}>
      {renderActivator ? (
        renderActivator(handleOpen)
      ) : (
        <IconButton size="small" onClick={handleOpen}>
          <MenuIcon fontSize="small" />
        </IconButton>
      )}

      <Menu open={open} anchorEl={anchorEl} onClose={close} {...props} />
    </MenuContext.Provider>
  );
};

export default MenuButton;
